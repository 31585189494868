<template>
  <svg
    :class="[classes, 'animate-spin'].join(' ')"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 200 200"
  >
    <radialGradient
      id="a10"
      cx=".66"
      fx=".66"
      cy=".3125"
      fy=".3125"
      gradientTransform="scale(1.5)"
    >
      <stop offset="0" :stop-color="color"></stop>
      <stop offset=".3" :stop-color="color" stop-opacity=".9"></stop>
      <stop offset=".6" :stop-color="color" stop-opacity=".6"></stop>
      <stop offset=".8" :stop-color="color" stop-opacity=".3"></stop>
      <stop offset="1" :stop-color="color" stop-opacity="0"></stop>
    </radialGradient>
    <circle
      transform-origin="center"
      fill="none"
      stroke="url(#a10)"
      stroke-width="15"
      stroke-linecap="round"
      stroke-dasharray="200 1000"
      stroke-dashoffset="0"
      cx="100"
      cy="100"
      r="70"
    ></circle>
    <circle
      transform-origin="center"
      fill="none"
      opacity=".2"
      :stroke="color"
      stroke-width="15"
      stroke-linecap="round"
      cx="100"
      cy="100"
      r="70"
    ></circle>
  </svg>
</template>

<script setup lang="ts">
const props = withDefaults(
  defineProps<{
    color?: string;
    classes?: string;
  }>(),
  {
    color: "#FFFFFF",
    classes: "size-8",
  }
);

const { color, classes } = toRefs(props);
</script>
