import { default as achievementsRFnKCvglPaMeta } from "/opt/build/repo/pages/achievements.vue?macro=true";
import { default as homeZXalWvqDQEMeta } from "/opt/build/repo/pages/home.vue?macro=true";
import { default as indexlaFeZuYZkUMeta } from "/opt/build/repo/pages/index.vue?macro=true";
import { default as kioskVhNNUDfYvGMeta } from "/opt/build/repo/pages/kiosk.vue?macro=true";
import { default as leaderboardITPRurvuAvMeta } from "/opt/build/repo/pages/leaderboard.vue?macro=true";
import { default as loginBXD9sq3T3sMeta } from "/opt/build/repo/pages/login.vue?macro=true";
import { default as profileNJNbyWXx9rMeta } from "/opt/build/repo/pages/profile.vue?macro=true";
import { default as redemptionsvgZD9thZWOMeta } from "/opt/build/repo/pages/redemptions.vue?macro=true";
import { default as setupfhBNKqtnAcMeta } from "/opt/build/repo/pages/setup.vue?macro=true";
export default [
  {
    name: "achievements",
    path: "/achievements",
    component: () => import("/opt/build/repo/pages/achievements.vue").then(m => m.default || m)
  },
  {
    name: "home",
    path: "/home",
    component: () => import("/opt/build/repo/pages/home.vue").then(m => m.default || m)
  },
  {
    name: "index",
    path: "/",
    component: () => import("/opt/build/repo/pages/index.vue").then(m => m.default || m)
  },
  {
    name: "kiosk",
    path: "/kiosk",
    component: () => import("/opt/build/repo/pages/kiosk.vue").then(m => m.default || m)
  },
  {
    name: "leaderboard",
    path: "/leaderboard",
    component: () => import("/opt/build/repo/pages/leaderboard.vue").then(m => m.default || m)
  },
  {
    name: "login",
    path: "/login",
    component: () => import("/opt/build/repo/pages/login.vue").then(m => m.default || m)
  },
  {
    name: "profile",
    path: "/profile",
    component: () => import("/opt/build/repo/pages/profile.vue").then(m => m.default || m)
  },
  {
    name: "redemptions",
    path: "/redemptions",
    component: () => import("/opt/build/repo/pages/redemptions.vue").then(m => m.default || m)
  },
  {
    name: "setup",
    path: "/setup",
    component: () => import("/opt/build/repo/pages/setup.vue").then(m => m.default || m)
  }
]