// plugins/disableTapHold.js

export default ({ app }, inject) => {
    if (process.client) {
      document.addEventListener('contextmenu', event => {
        if (event.target.classList.contains('no-select')) {
          event.preventDefault();
        }
      }, false);
    }
  };
  