import { useSnackbarStore } from "~/stores/snackbar";
export const $api = (path: string, options?: any) => {
  const config = useRuntimeConfig();
  const snackbar = useSnackbarStore();
  const route = useRoute();
  const apiFetch = $fetch.create({
    baseURL: config.public.apiURL,
    onRequest({ options }) {
      let headers: Record<string, string> = {};
      const token = useCookie("player-portal-token").value;
      if (token) {
        headers.Authorization = `Bearer ${token}`;
      }
      options.headers = headers;
    },
    onResponseError({ response }) {
      if (response.status === 401) {
        if (useCookie("player-portal-token").value != "") {
          snackbar.toast(response._data.message, "error");
        }else if(response._data.message){
          snackbar.toast(response._data.message, "error");
        }
        useCookie("player-portal-token").value = "";
        if (route.query.timeout && route.path === "/home") {
          navigateTo("/kiosk");
        } else if (route.path !== "/kiosk") {
          navigateTo("/login");
        }
      } else {
        snackbar.toast(response._data.message, "error");
      }
    },
  });
  return apiFetch(path, options);
};
