<template>
  <div
    class="h-full flex justify-center items-center fixed top-0 left-0 z-[9999] bg-[#0A0A0AE5] w-screen"
  >
    <div
      class="flex flex-col items-center survey-step max-w-[563px] w-full md:border-[1px] border-solid border-[#FF8000] p-[30px] md:max-h-[871px] h-full justify-center"
      :class="surveyStep == 1 ? 'show' : ''"
    >
      <h2 class="text-center text-white capitalize text-4xl mb-6">
        Unlock <span class="font-bold text-[#FF8000] text-5xl">500pts</span>
      </h2>
      <p class="text-center text-white text-xl max-w-[250px] leading-[1.2]">
        Complete a brief survey to earn additional points.
      </p>
      <button
        class="btn bg-playmaxx !text-pmblack focus:bg-playmaxxfocus transition duration-300 focus:!text-white cursor-pointer rounded-lg !w-full mt-12"
        type="button"
        @click="completeSurveyClick"
      >
        COMPLETE SURVEY
      </button>
      <button
        class="btn bg-white text-black transition duration-300 focus:!bg-whitefocus cursor-pointer !w-full rounded-lg mt-10"
        type="button"
        @click="closeSurvey"
      >
        BACK
      </button>
    </div>
    <div
      class="survey-step bg-black p-[30px] max-w-[563px] w-full md:max-h-[871px] h-full md:border-[1px] border-solid border-[#FF8000]"
      :class="surveyStep == 2 ? 'show' : ''"
    >
      <div class="flex mb-[30px]">
        <img
          src="/logo.png"
          alt="logo"
          class="object-contain w-[150px] relative z-10"
        />
        <h2 class="text-white text-2xl ml-5 capitalize relative z-10">
          Survey
        </h2>
        <img
          src="/back-button.svg"
          alt="logo"
          class="object-contain ml-auto w-[30px] relative z-10"
        />
        <img
          src="/assets/curve.svg"
          alt="cruve"
          class="absolute w-full top-[60px] left-0 md:hidden block"
        />
      </div>

      <div class="question-form" :class="showThankYou ? '' : 'show'">
        <p class="text-white mb-3">Sponsored by</p>
        <div class="bg-[#959595] w-full h-[64px] md:h-[80px]"></div>
        <!-- {{ surveyQuestions }} -->
        <div class="question-group">
          <div
            v-for="(question, index) in surveyQuestions"
            :key="index"
            :class="`question question-${index + 1} mt-[30px]`"
          >
            <p class="text-white font-bold mb-4 text-xl leading-[1.2]">
              {{ question.question }}
            </p>
            <p
              class="error text-[#FF0000] mb-4 -mt-3 text-base leading-[1.2]"
              v-if="showError && !answers[question.key]"
            >
              Please provide an answer
            </p>

            <div class="grid gap-3">
              <div
                v-for="(option, optIndex) in question.options"
                :key="optIndex"
                class="flex items-center gap-2 cursor-pointer relative"
              >
                <input
                  type="radio"
                  :id="`${question.key}-${optIndex}`"
                  :name="question.key"
                  :value="option"
                  @click="answers[question.key] = option"
                />
                <div class="mark"></div>
                <label
                  :for="`${question.key}-${optIndex}`"
                  class="p-0 text-md"
                  >{{ option }}</label
                >
              </div>
            </div>
          </div>

          <p
            class="error text-[#FF0000] -mb-3 mt-5 text-base leading-[1.2]"
            v-if="showError && !question1 && !question2 && !question3"
          >
            Please answer all questions before submitting.
          </p>
          <div class="mt-[40px] flex items-center justify-center">
            <button
              class="btn bg-playmaxx !text-pmblack focus:bg-playmaxxfocus disabled:opacity-50 transition uppercase duration-300 focus:!text-white md:!w-[350px] !w-full"
              type="button"
              @click="validateSurvey"
              :disabled="loading"
            >
              <Spinner v-if="loading" />
              {{ loading ? $t("general.processing") : $t("general.submit") }}
            </button>
          </div>
        </div>
      </div>
      <div class="question-form thankyou" :class="showThankYou ? 'show' : ''">
        <div class="flex-1 flex justify-center flex-col">
          <h2 class="text-white text-center text-3xl mb-2 mt-[30px]">
            THANK YOU!
          </h2>
          <p class="text-white text-center text-xl mb-6">You've earned</p>
          <h2 class="text-[#FF8000] text-center font-bold text-5xl capitalize">
            +500pts
          </h2>
          <p
            class="text-white text-center text-lg leading-[1.2] max-w-[270px] mx-auto mt-4"
          >
            Keep an eye out for more surveys to earn more points or unlock
            deals!
          </p>
        </div>

        <div class="">
          <p class="text-white mb-3 text-">Sponsored by</p>
          <div class="bg-[#959595] w-full h-[64px] md:h-[80px]"></div>

          <button
            class="btn bg-playmaxx !text-pmblack focus:bg-playmaxxfocus transition duration-300 focus:!text-white mt-5 !w-full"
            type="button"
            @click="surveyPopup = false"
          >
            CLOSE
          </button>
        </div>
      </div>
    </div>
  </div>
</template>
<script setup lang="ts">
import type { PagedList, SurveyGame, SurveyType } from "~/types";

const user = useUserStore();
const surveyStep = ref(1);
const showThankYou = ref(false);
const answers = ref<{ [key: string]: string | null }>({});

const popup = usePopupStore();
const { surveyPopup } = storeToRefs(popup);

class SurveyQuestion {
  question = "";
  options = [];
  key = "";
}

const surveyQuestions = ref<SurveyQuestion[]>([]);

onMounted(async () => {
  getSurveyQuestions();
});

const loading = ref(false);

const completeSurveyClick = () => {
  setTimeout(() => {
    surveyStep.value = 2;
  }, 200);
};

const closeSurvey = () => {
  surveyPopup.value = false;
  const surveyPopupCookie = useCookie("surveyPopup", {
    path: "/",
    maxAge: 86400,
  });

  surveyPopupCookie.value = "true";
};

const getSurveyQuestions = async () => {
  const surveyTypes = await $api("/surveyType", {
    params: {
      name: "game",
      orderBy: "dateCreated",
      order: "DESC",
      page: 1,
      pageSize: 1,
    },
  }).then((res) => res as PagedList<SurveyType>);
  if (surveyTypes.items[0] != null) {
    surveyQuestions.value = JSON.parse(surveyTypes.items[0].questions);
  }
};

const submitSurvey = async () => {
  await $api("/surveyGame", {
    method: "POST",
    body: {
      playerId: user.playerId,
      ...answers.value,
    },
  }).then((res) => res as SurveyGame);
  loading.value = false;
};

const question1 = ref("");
const question2 = ref("");
const question3 = ref("");

const showError = ref(false);
const validateSurvey = () => {
  loading.value = true;
  showError.value = false;

  // Check if all questions have been answered
  const allAnswered = surveyQuestions.value.every(
    (question) => answers.value[question.key]
  );

  if (allAnswered) {
    console.log(answers.value);
    submitSurvey();
    setTimeout(() => {
      showThankYou.value = true;
    }, 200);
  } else {
    showError.value = true;
    loading.value = false;
  }
};
</script>

<style scoped>
.survey-step {
  opacity: 0;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  transition: all 0.5s ease-in-out;
  visibility: hidden;
  z-index: 0;
  overflow-y: auto;
  -ms-overflow-style: none;
  scrollbar-width: none;

  &::-webkit-scrollbar {
    display: none;
  }

  @media (max-width: 767px) {
    top: 0 !important;
    transform: translateX(-50%);
  }
}

.survey-step.show {
  top: 50%;
  opacity: 1;
  visibility: visible;
  z-index: 9;
}

.question-form {
  opacity: 0;
  position: relative;
  top: 0px;
  transition: opacity 0.5s ease-in-out, visibility 0.5s ease-in-out;
  visibility: hidden;
  height: 0;
  z-index: 0;
  display: none;

  @media (max-width: 767px) {
    margin-top: 50px;
  }
}

.question-form.show {
  top: 0;
  opacity: 1;
  visibility: visible;
  height: auto;
  z-index: 9;
  display: block;
}

.mark {
  width: 18px;
  height: 18px;
  border-radius: 100px;
  border: 2px solid #ff8000;
  position: relative;

  &::after {
    content: "";
    width: 9px;
    height: 9px;
    background-color: #ff8000;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    display: block;
    border-radius: 100px;
    opacity: 0;
  }
}

.thankyou {
  position: absolute;
  top: 50% !important;
  left: 50%;
  transform: translate(-50%, -50%);
  height: calc(100% - 60px) !important;
  display: flex !important;
  flex-direction: column;
  justify-content: center;
  width: calc(100% - 60px);
  margin-top: 0 !important;
}

input:checked + .mark {
  &::after {
    opacity: 1;
  }
}

input {
  width: 18px !important;
  height: 18px !important;
  position: absolute;
  left: 0;
  opacity: 0;
  z-index: 2;
}

button {
  @media (max-width: 767px) {
    height: 45px;
  }
}
</style>
