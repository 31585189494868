import { defineStore } from 'pinia'

export const useSnackbarStore = defineStore('snackbar', {
  state: () => ({
    show: false,
    message: '',
    type: 'success'
  }),
  actions: {
    toast(message: string, type = 'success') {
      this.show = true
      this.message = message
      this.type = type
    }
  }
})