import { useUserStore } from "~/stores/user"

const whitelist = ['/', '/signup', '/setup', '/login', '/kiosk']
const either = ['/leaderboard']

export default defineNuxtRouteMiddleware(async (to, from) => {
  if (!whitelist.includes(to.path)) {
    const token = useCookie('player-portal-token').value
    const user = useUserStore()
    if (either.includes(to.path)) {
      if (token) {
        if (!user.playerId) {
          await user.getUserInfo()
        }
      }
    } else {
      if (!token) {
        return navigateTo('/login')
      } else {
        if (!user.playerId) {
          await user.getUserInfo()
        }
      }
    }
  }
})