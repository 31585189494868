import { defineStore } from 'pinia'
import { $api } from '~/composables/api'
import type { Auth, IUser } from '~/types'

export const useUserStore = defineStore('user', {
  state: (): IUser => ({
    playerId: 0,
    name: '',
    alias: '',
    email: '',
    city: '',
    mobile: '',
    avatarUrl: '',
    points: 0,
    todayPoints: 0,
    dateCreated: new Date(),
    dateUpdated: new Date(),
    accountId: 0,
    account: undefined,
    levelId: 0,
    level: undefined,
    languageId: 0,
    language: undefined,
    currentRFIDTagId: undefined,
    currentRFIDTag: undefined,
    pass: undefined,
    currentRank: 0,
    previousLogin: undefined,
    dateOfBirth: undefined,
    gender: '',
  }),
  getters: {
    loggedIn: (state): boolean => {
      return !!state.playerId && !!state.name
    },
    profileCompleted: (state): boolean => {
      return !!state.alias && !!state.email && !!state.avatarUrl
    }
  },
  actions: {
    reset() {
      this.setUser({
        playerId: 0,
        name: '',
        alias: '',
        email: '',
        city: '',
        mobile: '',
        avatarUrl: '',
        dateOfBirth: undefined,
        gender: '',
        points: 0,
        todayPoints: 0,
        dateCreated: new Date(),
        dateUpdated: new Date(),
        accountId: 0,
        account: undefined,
        levelId: 0,
        level: undefined,
        languageId: 0,
        language: undefined,
        currentRFIDTagId: undefined,
        currentRFIDTag: undefined,
        pass: undefined,
        currentRank: 0,
        previousLogin: undefined
      } as IUser)
    },
    setUser(data: IUser) {
      this.playerId = data.playerId
      this.name = data.name
      this.alias = data.alias
      this.email = data.email
      this.mobile = data.mobile
      this.city = data.city
      this.avatarUrl = data.avatarUrl
      this.dateOfBirth = data.dateOfBirth
      this.gender = data.gender
      this.points = data.points
      this.todayPoints = data.todayPoints
      this.dateCreated = data.dateCreated
      this.dateUpdated = data.dateUpdated
      this.accountId = data.accountId
      this.account = data.account
      this.levelId = data.levelId
      this.level = data.level
      this.languageId = data.languageId
      this.language = data.language
      this.currentRFIDTagId = data.currentRFIDTagId
      this.currentRFIDTag = data.currentRFIDTag
      this.pass = data.passes ? data.passes[0] : undefined
      this.currentRank = data.currentRank
      this.previousLogin = data.previousLogin
    },
    async login(alias: string, password: string) {
      try {
        useCookie('player-portal-token', { maxAge: 60 * 60 * 24 }).value = ''
        const data = await $api('/auth/player', {
          method: 'POST',
          body: {
            alias,
            password,
          },
        }).then(res => res as Auth)
        this.setUser(data.user)
        useCookie('player-portal-token', { maxAge: 60 * 60 * 24 }).value = data.token
      } catch (error) {
        console.error(error)
      }
    },
    async loginWithKey(key: string, id: number) {
      try {
        useCookie('player-portal-token', { maxAge: 60 * 60 * 24 }).value = ''
        const data = await $api('/auth/player/key', {
          method: 'POST',
          body: {
            key,
            id,
          },
        }).then(res => res as Auth)
        this.setUser(data.user)
        useCookie('player-portal-token', { maxAge: 60 * 60 * 24 }).value = data.token
      } catch (error) {
        console.error(error)
        throw error
      }
    },
    async getUserInfo() {
      try {
        const data = await $api('/auth/me').then(res => res as IUser)
        this.setUser(data)
      } catch (error) {
        console.error(error)
      }
    },
    async buyTicket({name, mobile}: {name: string, mobile: string}) {
      const data = {
        name,
        mobile,
        password: 'PlayMAXX2024',
        parkId: 1
      }
      await $api('/Player/buyTicket', {
        method: 'POST',
        body: data
      })
    },
    async setupStepOne({name, city, email, mobile}: {
      name: string;
      city: string;
      email: string;
      mobile: string;}) {
      const data = {
        name,
        city,
        email,
        mobile,
      }
      await $api('/auth/me', {
        method: 'PATCH',
        body: data
      })
      await this.getUserInfo()
    },
    async setupStepTwo({alias, parkId, avatarUrl}: {alias: string, parkId: number, avatarUrl: string}) {
      const data = {
        alias,
        parkId,
        avatarUrl
      }
      await $api('/auth/me', {
        method: 'PATCH',
        body: data
      })
      await this.login(alias, 'PlayMAXX2024')
    },
    async updateProfile({name, city, email, mobile, alias, parkId, avatarUrl, dateOfBirth, gender}: { name: string, city: string, email: string, mobile: string, alias: string, parkId: number, avatarUrl: string, dateOfBirth: Date, gender: string }) {
      const data = {
        name,
        city,
        email,
        mobile,
        alias,
        parkId,
        avatarUrl,
        dateOfBirth,
        gender
      }
      await $api('/auth/me', {
        method: 'PATCH',
        body: data
      })

      await this.getUserInfo()
    },
    async logout() {
      await $api('/auth', {
        method: 'DELETE'
      })
      this.reset()
      useCookie('player-portal-token', { maxAge: 60 * 60 * 24 }).value = ''
    }
  },
})