<template>
    <div
        class="fixed z-50 flex justify-center items-start lg:pt-0 lg:items-center pt-[40px] w-full h-full top-0 left-0 bg-[rgba(0,0,0,0.9)] transition-all duration-300"
        :class="{
            'opacity-0 invisible': !popup.showPopup,
            'opacity-1 visible': popup.showPopup,
        }"
    >
        <div class="absolute w-full h-full left-0 top-0" @click="closePopup"></div>
        <div
            class="w-[339px] max-w-full max-h-[calc(100vh-155px)] overflow-auto bg-black p-[30px] pb-[50px] flex flex-col shadow-[0px_0px_12.5px_0px_rgba(255,255,255,0.40)] rounded-[10px] bg-center bg-cover relative"
            :style="{
                backgroundImage: 'url(' + bgImage + ')',
            }"
        >
            <button @click="closePopup" class="inline-block w-[18px] h-[18px] object-contain ml-auto transition-all duration-300 hover:opacity-70">
                <img src="/times.svg" alt="back" />
            </button>
            <img
                v-if="type === 'thegetaway'"
                src="~/assets/img/the-getaway.png"
                alt="achievement"
                class="w-auto max-h-[73px] object-contain mx-auto mb-2 relative left-[-15px] mt-[-9px]"
            />
            <img
                v-if="type === 'firewall'"
                src="~/assets/img/logo-firewall.png"
                alt="achievement"
                class="h-[50px] object-contain mx-auto mb-5"
            />
            <img
                v-if="type === 'crackthesafe'"
                src="~/assets/img/logo-safe.png"
                alt="achievement"
                class="h-[35px] object-contain mx-auto mb-[26px] mt-[9px]"
            />
            <img
                v-if="type === 'puzzle'"
                src="~/assets/img/logo-puzzle.png"
                alt="achievement"
                class="h-[36px] object-contain mx-auto mt-3 mb-[26px]"
            />

            <p
                class="text-[12px] leading-[114%] text-[#898B8E] text-center mb-6"
            >
                The Nerang Mint is locked up like fort Knox! Carefully guarded
                by Macca who takes great pride in his security measures. It’s
                going to be tough. Even if you solve the puzzle, disarm the
                firewall and crack the safe to steal the goodies inside. The
                moment you...
            </p>
            <h3 class="text-center text-[18px] leading-[120%] mb-[25px]">
                Achievements
            </h3>
            <div class="grid grid-cols-2 gap-[22px]">
                <div
                    v-for="(achievement, index) in textMap[type]"
                    :key="index"
                    class="achievement-item"
                >
                    <img
                        :src="
                            '/achievements/' + type + '-' + (index + 1) + '.svg'
                        "
                        alt="achievement"
                    />
                    <h4>{{ achievement.name }}</h4>
                    <p>{{ achievement.text }}</p>
                </div>
            </div>
        </div>
    </div>
</template>

<script setup>
import { computed } from "vue";

const popup = usePopupStore();
const { type } = storeToRefs(popup);

const closePopup = () => {
    popup.showPopup = false;
};

const bgImage = computed(() => {
    return new URL(
        `../assets/img/achievement-bg/${type.value}.png`,
        import.meta.url
    ).href;
});

const textMap = {
    thegetaway: [
        {
            name: "Chauffeur",
            description: "Are you picking up Miss Daisy later?",
            text: "Complete the race",
        },
        {
            name: "Operator",
            description: "You would do OK as a NY cab driver.",
            text: "Complete the race in under 1 minute",
        },
        {
            name: "Driver",
            description: "Let's go! Are you free for another job next week?",
            text: "Complete the race in under 50 seconds",
        },
        {
            name: "Wheel Man",
            description: "Hey Nick, Gone in 40s!",
            text: "Complete the race in under 40 seconds",
        },
    ],
    firewall: [
        {
            name: "White Hat",
            description: "Didn't you work at CrowdStrike?",
            text: "Complete the challenge",
        },
        {
            name: "Red Hat",
            description: "You have been watching the Matrix",
            text: "Complete the challenge in 1 round",
        },
        {
            name: "Grey Hat",
            description: "I think we will call you NEO",
            text: "Complete the challenge with no incorrect answers (for that round)",
        },
        {
            name: "Black Hat",
            description: "One word SWORDFISH.",
            text: "Complete the challenge in less than 45 seconds",
        },
    ],
    crackthesafe: [
        {
            name: "Copper",
            description: "Here is my piggybank, try this.",
            text: "Complete the challenge",
        },
        {
            name: "Silver",
            description: "Not bad, what's your name again?",
            text: "Complete the challenge with no incorrect attempts",
        },
        {
            name: "Gold",
            description: "Have you seen the Italian job?",
            text: "Complete the challenge in less than 1 minute",
        },
        {
            name: "Platinum",
            description: "You now work for us.",
            text: "Complete the challenge in less than 45 seconds",
        },
    ],
    puzzle: [
        {
            name: "Smart",
            description: "You're more suited to a newspaper crossword.",
            text: "Solve A Puzzle",
        },
        {
            name: "Intelligent",
            description: "You're OK, let's see how you go on the next one.",
            text: "Solve 3 Puzzles",
        },
        {
            name: "Brilliant",
            description: "Are you new? We are looking for members.",
            text: "Solve all 5 Puzzles",
        },
        {
            name: "Genius",
            description: "You're a criminal mastermind.",
            text: "Solve all 5 Puzzles in 45 seconds or less",
        },
    ],
};
</script>

<style scoped>
.achievement-item {
    img {
        @apply mx-auto;
    }
    h4 {
        @apply text-[#FF8000] text-[20px] font-bold leading-[120%] my-0.5 text-center;
    }
    p {
        @apply text-[#898B8E] text-[12px] font-normal leading-[114%] text-center;
    }
}
</style>
